<template>
  <div>
    <div class="bgcenters"></div>
    <div>
      <headbox title="卖出结算" bgColor="#ff2c30" iswhite="true"></headbox>
        <div v-if="isLoading">
      <center id="loadingbox">
        <van-loading size="68px" />
      </center>
        </div>
      <div class="centerboxs" v-if="!isLoading">
        <!-- 定价详情 -->
        <div class="topbg"></div>
        <!-- 订单详情 -->
        <div class="orderbox">
          <center>
            <!-- 用户没确认 -->
            <h1 class="f32" v-if="+datalist.status == 2">
              请确认下方结算信息
            </h1>
            <!-- 用户已经确认 -->
            <h1 class="f32" v-if="+datalist.status == 1">
              打款已完成，请查收
            </h1>
            <h1 class="f32" v-if="+datalist.status == 0">您的款项正在飞速打款中....</h1>
            <div class="money">
              <b class="f32">￥</b>
              {{
                  datalist.moneyTotal -
                  datalist.moneyYunFei -
                  datalist.moneyTiChun -
                  datalist.moneyBaoXiang
              }}
            </div>
            <el-button class="btnsee f32" @click="dianzidanju"
              >查看详情</el-button
            >
          </center>
        </div>
        <div class="topcenter">
          <div class="toptitle">物料明细</div>
          <div>
            <div class="flex-between" :key="0">
              <div>来料重量</div>
              <div>{{ datalist.weightLaiLiaoTotal }}克</div>
            </div>            
            <div class="flex-between" :key="1">
              <div>折足总重</div>
              <div>{{ datalist.weightLaiLiaoZeZuTotal }}克</div>
            </div>
          </div>

          <!-- 结算明细 -->
          <div class="toptitle mart">结算明细</div>
          <!-- <div v-for="(items, index) in datalist.itemList" :key="index"> -->
          <div class="sectionbox">
            <!-- <div class="flex-betweens"> -->
            <!-- <div>{{ items.name }}</div>
                <div>{{ items.weightZheZu }}克</div> -->
            <!-- </div> -->
            <!-- <div class="flex-betweens"> -->
            <!-- <div>{{ items.name }}结算价格(单价)</div> -->
            <!-- <div>{{ items.price }}元</div> -->
            <!-- </div> -->
            <div class="flex-betweens">
              <div>合计金额：</div>
              <div class="blackFont">{{ datalist.moneyTotal }}元</div>
            </div>
          </div>
          <!-- </div> -->
          <!-- 合计金额 -->
          <div class="toptitle mart">合计金额</div>
          <div>
            <div class="flex-between">
              <div>提纯费：</div>
              <div class="blackFont">{{ datalist.moneyTiChun }}元</div>
            </div>
            <div class="flex-between">
              <div>快递费：</div>
              <div class="blackFont">{{ datalist.moneyYunFei }}元</div>
            </div>
            <div class="flex-between">
              <div>保险费：</div>
              <div class="blackFont">{{ datalist.moneyBaoXiang }}元</div>
            </div>
            <div class="flex-between">
              <div>合计费用：</div>
              <div class="countyellow">
                {{
                  datalist.moneyTotal -
                    datalist.moneyYunFei -
                    datalist.moneyTiChun -
                    datalist.moneyBaoXiang
                }}元
              </div>
            </div>
          </div>

          <!-- 订单信息 -->
          <div class="toptitle mart ">订单信息</div>
          <div>
            <div class="flex-between">
              <div>顺丰单号：</div>
              <div>{{ datalist.orderNoExpress }}</div>
            </div>
            <div class="flex-between">
              <div>结算单号：</div>
              <div>{{ datalist.orderNo }}</div>
            </div>
            <div class="flex-between">
              <div>开单时间：</div>
              <div>{{ datalist.date }}</div>
            </div>
            <div class="flex-between">
              <div>用户姓名：</div>
              <div>{{ datalist.name }}</div>
            </div>
            <div class="flex-between">
              <div>联系电话：</div>
              <div>{{ datalist.phone }}</div>
            </div>
            <div class="flex-between">
              <div>收款账号：</div>
              <div>{{ datalist.bankName + datalist.bankNum }}</div>
            </div>
          </div>
        </div>
        <!-- 用户没确认 -->
        <center v-if="+datalist.status == 2" class="rel">
          <el-button class="btn_red onlybox" @click="havapro"
            >对结算单有异议？</el-button
          >
          <el-button class="btn_red onlyboxsecond_yellow" @click="postForm"
            >确认无误</el-button
          >
        </center>

        <center v-if="+datalist.status == 0" class="rel">
          <el-button class="btn_red onlybox" @click="havapro"
            >对结算单有异议？</el-button
          >
          <el-button
            class="btn_red overconfirm onlyboxsecond"
            >已确认</el-button
          >
        </center>

        <!-- 用户已确认 -->
        <center v-if="+datalist.status == 1" class="rel">
          <el-button class="btn_red" style="width:90%" @click="addover"
            >再来一单</el-button
          >
        </center>
      </div>
      <!-- 弹窗 -->
      <van-dialog :showConfirmButton="false"  v-model="popupbox">
        <div class="titlebox_popup">提示</div>
        <div class="detailsbox">
          如结算单有误需拨打{{GLOBAL}}客服人工核对修改，是否拨打{{GLOBAL}}客服电话：4001-886-722
        </div>
        <div class="flex btn-flex rel">
          <div class="canclebox" @click="popupbox = !popupbox">取消</div>
          <a class="redF"  :href="'tel:' + '4001-886-722'">确认</a>
          <!-- <div class="redF" @click="popupbox = !popupbox"></div> -->
        </div>
      </van-dialog>
      <!-- 确认无误 -->
      <van-dialog :showConfirmButton="false" v-model="iscomfirm">
        <div class="titlebox_popup">结算确认</div>
        <div class="detailsbox">
          尊敬的用户，您正在进行结算确认，请仔细核对您的来料重量与结算价格。确认无误后，{{GLOBAL}}将在一个工作日内完成打款。
        </div>
        <div class="flex btn-flex rel">
          <div class="canclebox" @click="iscomfirm = !iscomfirm">取消</div>
          <div class="redF" @click="querenbox">确认</div>
        </div>
      </van-dialog>
    </div>
  </div>
</template>

<script>
import headbox from "@/components/head.vue";
import "vant/lib/popup/style";
// 结算详情
import * as myselldetails from "@/utils/api";

export default {
  data() {
    return {
      bankuser: "-",
      iscomfirm: false,
      popupbox: false,
      // 付款状态
      payType: -1,
      // 结算详情数据
      datalist: {},
      isLoading:true,//加载状态
    };
  },
  filters: {
    repalceadd(val) {
      if (val) {
        return val.replace("+", "");
      } else {
        return val;
      }
    }
  },
  methods: {
    // 查看电子保单
    dianzidanju() {
      this.$router.push({
        name: "selldetails_table",
        query: { oid: this.datalist.orderId }
      });
    },
    // 再来一单
    addover() {
      this.$router.push({ name: "pricing" });
    },
    querenbox() {
      myselldetails
        .sureJieSuanOrder({ orderId: this.$route.query.orderid })
        .then(res => {
          this.$router.push({ name: "sellSettlement",active:'Settlement' });
        });
    },
    // 确认无误
    postForm() {
      this.iscomfirm = true;
    },
    // 有疑问
    havapro() {
      this.popupbox = true;
    }
  },
  mounted() {
    myselldetails.banklist().then(res => {
      console.log(res);
      this.bankuser = res[0].bankCard.prettyBankNumber;

    });
  

               // 付款状态 0 待付款  1 已付款  2 待确认
           this.payType = this.$route.query.payType;
           const postData = {
             orderType:+this.payType,
             orderId: this.$route.query.orderid || "0"
           };
           myselldetails.queryJieSuanOrderDetail(postData).then(res => {
             this.datalist = res.data;
             console.log('datalist',this.datalist);
             this.isLoading = false
            //  debugger;
           });
  
  },
  components: {
    headbox
  }
};
</script>
<style lang="scss" scoped="true">
.onlyboxsecond {
  border: 1px solid #e0e0e0 !important;
}
.onlyboxsecond_yellow {
  border: 1px solid #ffa102 !important;
}

.bgcenters {
  position: relative;
  z-index: 3;
}
.redF {
  color: #ff2c30;
}
.canclebox {
  &:after {
    content: "";
    background: #cccccc;
    width: 1px;
    height: 90px;
    top: 0;
    position: absolute;
    left: 50%;
    transform: translate(-50%);
  }
}
.btn-flex {
  padding: 20px 0;
  font-size: 32px;
  justify-content: space-around;
  border-top: 1px solid #cccccc;
}
::v-deep {.van-popup--center {
  width: 600px;
  border-radius: 12px;
}
.van-dialog{
  width: 80%;
}
}
.detailsbox {
  color: #292929;
  font-size: 30px;
  padding: 0 40px 30px;
}
.titlebox_popup {
  font-size: 36px;
  text-align: center;
  color: #212121;
  margin: 30px 0 20px;
}
.sectionbox {
  margin-bottom: 30px;
  .flex-betweens {
    margin-bottom: 7px;
    font-size: 28px;
    color: #999999;
  }
}
.flex-between_ {
  line-height: 38px;
  margin-bottom: 7px;
  font-size: 28px;
  color: #999999;
  div:nth-child(1) {
    margin-right: 70px;
  }
}
.flex-betweens {
  display: flex;
  justify-content: space-between;
}
.countyellow {
  color: #ffb606 !important;
}
.blackFont {
  color: #010000 !important;
}
.mart {
  margin-top: 60px;
}
.toptitle {
  margin-bottom: 25px;
  font-size: 36px;
  color: #010000;
  font-weight: bold;
}
.topcenter {
  margin-top: 250px;
  padding: 0 60px 150px;
}
.money {
  padding: 40px 0;
  font-size: 52px;
}
.f32 {
  font-size: 32px;
}

.btnsee {
  color: #fff;
  width: 280px;
  height: 72px;
  background: #FF2D2E;
  border-radius: 32px;
  font-size: 28px;
}
.btn_red {
  width: 320px;
  position: relative;
  bottom: 30px;
  border-radius: 8px;
  height: 80px;
  border: 0;
  background: #FF2D2E;
  font-size: 36px;
  color: #fff;
}
.onlybox {
  font-size: 28px;
  border: 1px solid #cccccc;
  color: #999999;
  background: #f5f5f5;
}
.overconfirm {
  position: relative;
  top: -27px;
  border: 1px solid #e0e0e0 !important;
  background: #e0e0e0;
}

.topboxcenter {
  position: absolute;
  top: 120px;
  left: 60px;
  i {
    font-size: 60px;
    margin-top: 10px;
  }
  .topbox {
    div {
      font-weight: bold;
      margin-left: 20px;
      font-size: 40px;
    }
    .orderdetails {
      font-weight: normal;
      font-size: 28px;
    }
  }
}
.bgcenter {
  background: #fff;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.centerboxs {
  position: relative;
  z-index: 1;
}
.ordermunber {
  font-weight: bold;
  font-size: 28px;
  color: #010000;
  border-bottom: 1px dotted #e6e6e6;
  padding-bottom: 25px;
  margin-bottom: 25px;
}
.flex-between div:nth-child(1) {
  color: #999999;
  font-size: 28px;
  margin-bottom: 15px;
}
.flex-between div:nth-child(2) {
  font-size: 28px;
  color: #999999;
}
.orderbox {
  width: 690px;
  padding: 50px;
  background: #fff;
  box-shadow: 0px 4px 4px 2px rgba(0, 0, 0, 0.1);
  border-radius: 8px 8px 8px 8px;
  position: absolute;
  left: 50%;
  margin-left: -345px;
  top: 20px;
  
}
.topbg {
  background: #ff2c30;
  width: 750px;
  height: 153px;
}
.redfont {
  color: #fa6054;
}
.footerbox {
  position: fixed;
  bottom: 30px;
  margin: 0 30px;
  .addone {
    border-radius: 8px;
    font-size: 36px;
    border: 1px solid #ffb606;
    color: #ffb606;
    height: 88px;
    width: 240px;
    margin-right: 30px;
  }
  .gosend {
    border-radius: 8px;
    font-size: 36px;
    width: 420px;
    height: 88px;
    border: 1px solid #ffb606;
    background: #ffb606;
    color: #000000;
  }
}
.imgminbox {
  margin-left: 25px;
  // margin: 0 auto;
  width: 64px;
  height: 64px;
  margin-bottom: 20px;
}
.bg_box {
  // background: red;
  background: #f7f7f7;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.personbox {
  z-index: 2;
  position: relative;
}
.righticon {
  width: 20px;
  right: 0;
  top: 30%;
  transform: translate(-50%);
  position: absolute;
}
.abs {
  position: absolute;
  top: 150px;
  left: 30px;
  color: #fff;
  .imgbox {
    width: 100px;
  }
}
.list {
  border-radius: 8px;
  margin-top: 20px;
  background: #fff;
}
.msgbox {
  border-radius: 8px;
  background: #fff;
  position: relative;
  top: -50px;
  padding: 30px 40px;
  width: 690px;
  margin: 0 auto;
  color: #999999;
  font-size: 24px;
  b {
    font-size: 40px;
    font-weight: normal;
  }
  .firstdiv {
    width: 320px;
    border-right: 1px dotted #e6e6e6;
  }
  .seconddiv {
    width: 300px;
    margin-left: 40px;
  }
}
.namebox {
  margin-left: 30px;
  font-size: 36px;
  .phone {
    font-size: 26px !important;
  }
}
.centerbox {
  padding: 0 30px;
  .mintitle {
    color: #343434;
    font-size: 30px;
    padding: 25px 30px;
    border-bottom: 1px solid #e6e6e6;
  }
  .flexs {
    padding: 0 25px;
    display: flex;
    div.flexbox {
      flex: 1;
      font-size: 26px;
      color: #707070;
    }
  }
  div.flexbox div:nth-child(2) {
    text-indent: 5px;
  }
  .flex {
    padding: 0 15px 0 35px;
    div.flexbox {
      flex: 1;
      font-size: 26px;
      color: #707070;
      margin: 35px 0px 60px 0;
    }
  }
}
#loadingbox{
  margin-top: 100px;
}
</style>
